import { Dashboard2Module } from './views/dashboard2/dashboard2.module';
import { MicroFrontendRouteReuseStrategy } from './services/route-reuse-strategy';
import { RouteReuseStrategy } from '@angular/router';
import { GerarWorkflowModule } from './views/gerar-workflow/gerar-workflow.module';

import { KeycloakService, KeycloakAngularModule } from 'keycloak-angular';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { RegisterComponent } from './views/register/register.component';



const APP_CONTAINERS = [
    DefaultLayoutComponent
];

import {
    AppAsideModule,
    AppBreadcrumbModule,
    AppHeaderModule,
    AppFooterModule,
    AppSidebarModule,
} from '@coreui/angular'

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ToastrModule } from 'ngx-toastr';
import { NgxUiLoaderModule, NgxUiLoaderRouterModule } from 'ngx-ui-loader';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthGuard } from './shared/guard';
import { MyHttpInterceptor } from './services/http.interceptor';
import { LoginModule } from './views/login/login.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { DashboardModule } from './views/dashboard/dashboard.module';
import { CampanhasModule } from './views/campanhas/campanhas.module';
import { AtendimentoConfiguracoesModule } from './views/atendimento-configuracoes/atendimento-configuracoes.module';
import { CamposModule } from './views/campos/campos.module';
import { LeadsModule } from './views/leads/leads.module';
import { RelatoriosModule } from './views/relatorios/relatorios.module';
import { IntegracoesModule } from './views/integracoes/integracoes.module';
import { CarteirasModule } from './views/carteiras/carteiras.module';
import { SegmentosModule } from './views/segmentos/segmentos.module';
import { GruposPermissoesModule } from './views/grupos-permissoes/grupos-permissoes.module';
import { UsuariosModule } from './views/usuarios/usuarios.module';
import { RegionaisModule } from './views/regionais/regionais.module';
import { TipoAtividadesModule } from './views/tipo-atividades/tipo-atividades.module';
import { TipoCampanhasModule } from './views/tipo-campanhas/tipo-campanhas.module';
import { ProdutosModule } from './views/produtos/produtos.module';
import { DoresModule } from './views/dores/dores.module';
import { CriteriosCarteiraModule } from './views/carteiras/criterios-carteira/criterios-carteira.module';
import { AppInitService } from './app-init.service';
import { switchMap } from 'rxjs/operators';
import { ConfigInitService } from '../config-init.service';
import { from, of } from 'rxjs';
import { SegmentacaoLeadsModule } from './views/segmentacao-leads/segmentacao-leads.module';
import { HealthCheckModule } from './views/health-check/health-check.module';
import { NgxMaskModule } from 'ngx-mask';
import { DashboardRabbitmqModule } from './views/dashboard-rabbitmq/dashboard-rabbitmq.module';
import { ConfirmModalModule } from './shared/confirm-modal/confirm-modal.module';
import { ComparacaoLeadsModule } from './views/comparacao-leads/comparacao-leads.module';

export function init_app(appLoadService: AppInitService) {
    return () => appLoadService.init();
}
export function initializeKeycloak(
    keycloak: KeycloakService,
    configService: ConfigInitService
) {
    return () =>
        configService.getConfig()
            .pipe(
                switchMap<any, any>((config) => {
                    //console.log(config);
                    if (!config.autenticarPorAmei) {
                        return of(true);
                    }
                    return from(keycloak.init({
                        config: {
                            url: config['KEYCLOAK_URL'],
                            realm: config['KEYCLOAK_REALM'],
                            clientId: config['KEYCLOAK_CLIENT_ID'],


                        },
                        initOptions: {
                            refreshToken: '100',
                            flow: 'implicit',
                            onLoad: 'login-required',
                            // silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
                        },
                        loadUserProfileAtStartUp: false

                    }))

                })
            ).toPromise()
}

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        AppAsideModule,
        AppBreadcrumbModule.forRoot(),
        AppFooterModule,
        AppHeaderModule,
        AppSidebarModule,
        PerfectScrollbarModule,
        BsDropdownModule.forRoot(),
        TabsModule.forRoot(),
        ToastrModule.forRoot({
            timeOut: 10000,
            positionClass: 'toast-top-right',
            preventDuplicates: true,
        }),
        NgxUiLoaderModule.forRoot({ "masterLoaderId": "loader-default" }),
        NgxUiLoaderRouterModule,
        NgxMaskModule.forRoot(),
        HttpClientModule,
        NgbModule,
        FormsModule,
        LoginModule,
        DashboardModule,
        Dashboard2Module,
        GruposPermissoesModule,
        UsuariosModule,
        CampanhasModule,
        AtendimentoConfiguracoesModule,
        CamposModule,
        LeadsModule,
        RelatoriosModule,
        IntegracoesModule,
        CarteirasModule,
        SegmentosModule,
        RegionaisModule,
        TipoAtividadesModule,
        TipoCampanhasModule,
        ProdutosModule,
        DoresModule,
        CriteriosCarteiraModule,
        KeycloakAngularModule,
        SegmentacaoLeadsModule,
        ComparacaoLeadsModule,
        HealthCheckModule,
        GerarWorkflowModule,
        NgbModule,
        ConfirmModalModule
    ],
    declarations: [
        AppComponent,
        ...APP_CONTAINERS,
        P404Component,
        P500Component,
        RegisterComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        {
            provide: RouteReuseStrategy,
            useClass: MicroFrontendRouteReuseStrategy
        },
        // { provide: LocationStrategy, useClass: HashLocationStrategy },
        AppInitService,
        {
            provide: APP_INITIALIZER,
            useFactory: init_app,
            deps: [AppInitService],
            multi: true
        },
        AuthGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MyHttpInterceptor,
            multi: true
        },

        ConfigInitService,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeKeycloak,
            multi: true,
            deps: [KeycloakService, ConfigInitService],
        }],
    bootstrap: [AppComponent]
})
export class AppModule { }
