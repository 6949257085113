import { NgxMaskModule } from 'ngx-mask';
import { CompleteDigitoCnpjModule } from './../../pipe/complete-digito-cnpj/complete-digito-cnpj.module';
import { CompleteDigitoCpfModule } from './../../pipe/complete-digito-cpf/complete-digito-cpf.module';

import { SidebarModalModule } from './../../shared/sidebar-modal/sidebar-modal.module';
import { MyNgbDateParserFormatter } from './../../shared/datepicker/MyNgbDateParserFormatter';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { NgbDateParserFormatter, NgbDatepickerConfig, NgbDatepickerI18n, NgbModule, NgbNavModule } from "@ng-bootstrap/ng-bootstrap";

import { Dashboard2Component } from './dashboard2.component';
import { Dashboard2RoutingModule } from './dashboard2-routing.module';

import { CustomDatepickerI18n, I18n } from '../../shared/datepicker/CustomDatepickerI18n';
import { NgbDatePTParserFormatter } from '../../shared/datepicker/NgbDatePTParserFormatter';
import { CustomNgbDatepickerConfig } from '../../shared/datepicker/CustomNgbDatepickerConfig';
import { OrderModule } from 'ngx-order-pipe';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { NgSelectModule } from '@ng-select/ng-select';
import { FilterModule } from '../../pipe/filter/filter.module';



@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        Dashboard2RoutingModule,
        ChartsModule,
        NgbNavModule,
        BsDropdownModule,
        NgbModule,
        OrderModule,
        SidebarModalModule,
        CompleteDigitoCpfModule,
        CompleteDigitoCnpjModule,
        NgxMaskModule,
        NgSelectModule,
        ProgressbarModule,
        FilterModule,
        ButtonsModule.forRoot(),
        TooltipModule.forRoot(),
    ],
    declarations: [Dashboard2Component],
    providers: [
        [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }],
        [{ provide: NgbDateParserFormatter, useClass: NgbDatePTParserFormatter }],
        [{ provide: NgbDatepickerConfig, useClass: CustomNgbDatepickerConfig }],
        MyNgbDateParserFormatter
    ]
})
export class Dashboard2Module { }
