import { MyNgbDateParserFormatter } from "./../../shared/datepicker/MyNgbDateParserFormatter";
//import { DashboardChartsData } from './dashboard-charts-data';
import { NgbDateStruct, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Component, OnInit, ViewChild } from "@angular/core";
//import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities';
//import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { Dashboard, Dashboard2Service } from "./dashboard2.service";
//codigo original
//https://github.com/coreui/coreui-free-angular-admin-template/tree/v2.1.0/src/app/views/dashboard
import { OrderPipe } from "ngx-order-pipe";
import moment from "moment";
import { FormatService } from "../../services/format.service";
import { ConteudoService } from "../../services/conteudo.service";
import { PlataformaService } from "../../services/plataforma.service";
@Component({
    templateUrl: "dashboard2.component.html",
    styleUrls: ["./dashboard2.component.scss"],
})
export class Dashboard2Component implements OnInit {
    @ViewChild("content") content;

    radioModel: string = "Month";
    data_inicial: NgbDateStruct = { day: null, month: null, year: null };
    data_final: NgbDateStruct = { day: null, month: null, year: null };

    filter = {
        inicio: this.customFormat.formatForServer(this.data_inicial),
        fim: this.customFormat.formatForServer(this.data_final),
        status: null,
        tipo_cliente: null,
        plataforma_id: null,
        campanha_id: null,
        identificador_id: null,
        atendimento: null,
        limit: 100,
    };
    public templates: { [key: number]: any } = {};
    public templates2: { [key: number]: any } = {};

    dashData = new Dashboard();
    plataformas = [];
    identificadores = [];
    controlAtualizaDashboard: any;

    detalhes = [];
    totalDetalhes = 0;
    sortedCollection: any[];
    order: string = "nome";
    filtro: string = "";
    orderDetalhes: string = "Plataformas.nome";
    reverse: boolean = false;
    reverseDetalhes: boolean = false;
    msgCarregando: string = "";
    campanha_id: number;
    plataforma_id: number;
    campanhas: any = [];
    plataformasSelect: any = [];
    constructor(
        private dashServices: Dashboard2Service,
        private orderPipe: OrderPipe,
        private modalService: NgbModal,
        private customFormat: MyNgbDateParserFormatter,
        private formatService: FormatService,
        private conteudoService: ConteudoService,
        private PlataformaService: PlataformaService
    ) {}

    initPeriodo() {
        let date = new Date();
        const dtInicio = new Date(date.getTime() - 30 * 24 * 60 * 60 * 1000); //ultimos 30 dias
        this.data_inicial = {
            year: dtInicio.getFullYear(),
            month: dtInicio.getMonth() + 1,
            day: dtInicio.getDate(),
        };
        let now = new Date();
        this.data_final = {
            year: now.getFullYear(),
            month: now.getMonth() + 1,
            day: now.getDate(),
        };
        this.filter = {
            inicio: this.customFormat.formatForServer(this.data_inicial),
            fim: this.customFormat.formatForServer(this.data_final),
            campanha_id: null,
            status: null,
            tipo_cliente: null,
            plataforma_id: null,
            identificador_id: null,
            atendimento: null,
            limit: 100,
        };
        this.atualizar();
    }
    open() {
        this.filtrar();
        this.modalService.open(this.content, { size: "xl", scrollable: true });
    }
    close() {
        this.modalService.dismissAll();
        this.filter = {
            inicio: this.customFormat.formatForServer(this.data_inicial),
            fim: this.customFormat.formatForServer(this.data_final),
            status: null,
            tipo_cliente: null,
            plataforma_id: null,
            campanha_id: null,
            identificador_id: null,
            atendimento: null,
            limit: 100,
        };
        this.detalhes = [];
        this.totalDetalhes = 0;
    }
    setFilter(param: any) {
        if (param) {
            // const key = Object.keys(param)[0];
            // this.filter[key] = param[key];
            this.filter = { ...this.filter, ...param };
            this.open();
        }
    }
    setOrder(value: string) {
        if (this.order === value) {
            this.reverse = !this.reverse;
        }

        this.order = value;
    }
    setOrderDetalhes(value: string) {
        if (this.orderDetalhes === value) {
            this.reverseDetalhes = !this.reverseDetalhes;
        }

        this.orderDetalhes = value;
    }
    mostrarColunaProtocolo(detalhes) {
        return detalhes.some((s) => s.protocolo_atendimento != null);
    }

    atualizar() {
        this.msgCarregando = "Aguarde, carregando...   ";
        this.dashServices
            .getDash(
                // this.customFormat.formatForServer(this.data_inicial),
                // this.customFormat.formatForServer(this.data_final)
                this.filter
            )
            .subscribe((data) => {
                this.dashData = data;
                this.plataformas = [];
                this.identificadores = [];
                Object.keys(this.dashData?.plataforma).forEach((index) => {
                    this.plataformas.push(this.dashData?.plataforma[index]);
                });
                Object.keys(this.dashData?.identificador).forEach((index) => {
                    this.identificadores.push(
                        this.dashData?.identificador[index]
                    );
                });
                this.sortedCollection = this.orderPipe.transform(
                    this.plataformas,
                    "nome"
                );
                this.msgCarregando = `Atualizado em ${moment().format(
                    "DD/MM/YYYY HH:mm"
                )}   `;
            });
    }

    filtrar() {
        this.dashServices.getDetalhes(this.filter).subscribe((data) => {
            this.detalhes = data["Filas"];
            this.totalDetalhes = data["total"];
        });
    }

    public somar(lista) {
        return lista.reduce((a, b) => a + b, 0);
    }
    public gerarPorcentagemTotal(item) {
        const aguardando = item?.Aguardando?.total || 0;
        const falha = item?.Falha?.total || 0;
        const sucesso = item?.Sucesso?.total || 0;
        const processando = item?.Processando?.total || 0;

        const totalGeral = aguardando + falha + sucesso +processando;

        if (totalGeral === 0) return 0;

        const percentualConcluidos = ((falha + sucesso) / totalGeral) * 100;

        return Math.round(percentualConcluidos);
    }
    public tooltipPorcentagem(tipo: number, item){
        if(tipo == 1){
            const totais = this.gerarPorcentagemFalhaSucesso(item);
            const falha = totais.find(f => f.type == "danger");
            const sucesso = totais.find(f => f.type == "success");
            return `Distribuição dos registros processados: <br/>Falha: ${falha.label} (${item?.Falha?.total || 0} registro(s)) <br/>Sucesso: ${sucesso.label} (${item?.Sucesso?.total || 0} registro(s))`;

        }else if(tipo == 2){
            const aguardando = item?.Aguardando?.total || 0;
            const falha = item?.Falha?.total || 0;
            const sucesso = item?.Sucesso?.total || 0;
            const processando = item?.Processando?.total || 0;

            const totalGeral = aguardando + falha + sucesso +processando;

            return `Progresso total: ${this.gerarPorcentagemTotal(item)}% dos registros foram processados.<br />Total de registros: ${totalGeral}`;
        }

    }
    public registerTemplate(index: number, templateRef: any, tipo: number): boolean {
        if(tipo == 1){
            this.templates[index] = templateRef;
        }else if(tipo == 2){
            this.templates2[index] = templateRef;
        }
        return true;
      }
    public gerarPorcentagemFalhaSucesso(item) {
        const falha = item?.Falha?.total || 0;
        const sucesso = item?.Sucesso?.total || 0;

        const totalGeral = falha + sucesso;

        if (totalGeral === 0) {
            [
                { value: 0, type: "danger", label: 0+"%" },
                { value: 0, type: "success", label: 0+"%" },
            ]
        }

        const percentualSucesso = Math.round((sucesso / totalGeral) * 100);
        const percentualFalha = Math.round((falha / totalGeral) * 100);
        return  [
            { value: percentualFalha, type: "danger", label: percentualFalha+"%" },
            { value: percentualSucesso, type: "success", label: percentualSucesso+"%" },
        ]


    }

    ngOnInit(): void {
        this.initPeriodo();
        this.controlAtualizaDashboard = setInterval(() => {
            //this.atualizar();
        }, 200000);
        this.conteudoService.get({limit: 1000}).subscribe(conteudos => {
            this.campanhas = conteudos.ConteudoPublicados.map((c: any) => {
                return {
                    id: c.id,
                    nome: c.identificador,
                };
            }).sort((a, b) => (a.nome < b.nome ? -1 : 1));
        });
        this.PlataformaService.listarTodasPlataformas().then(response => {
            this.plataformasSelect = response;
          });


    }
    ngOnDestroy() {
        if (this.controlAtualizaDashboard) {
            clearInterval(this.controlAtualizaDashboard);
        }
    }

    getClassStatus(status: string) {
        return this.formatService.getClassBadgeStatus(status);
    }
}
