import { Component, OnInit, ViewChild } from "@angular/core";
//import { ComparacaoLeadsService } from '../../services/comparacao-leads.service';
import { ToastrService } from "ngx-toastr";
import { AbstractControl, FormBuilder, NgForm, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { ConteudoService } from "../../services/conteudo.service";
import { ConteudoImportacaoService } from "../../services/conteudo-importacao.service";
import { ComparacaoLeadsService } from "../../services/comparacao-leads.service";
import { error } from "console";
export function cnpjRequiredValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const comparacoes = control.parent?.value;
      if (!comparacoes) return null;
      const de = comparacoes.de;
      const cnpj = control.value;

      if (de === 'CPF+CNPJ' && (!cnpj || cnpj.trim() === '')) {
        return { cnpjRequired: true };
      }

      return null;
    };
  }
@Component({
    selector: "app-comparacao-leads",
    templateUrl: "./comparacao-leads.component.html",
    styleUrls: ["./comparacao-leads.component.css"],
})
export class ComparacaoLeadsComponent implements OnInit {
    form: any;
    fileSizeError: any = false;
    csvToJson: any;
    campanhas: any = [];
    arquivosImportados: any = [];
    //comparar: any = [{nome: "CPF"},{nome: "CNPJ"},{nome: "Email"},{nome: "CPF+CNPJ"}];
    comparar: any = ["CPF", "CNPJ", "Email", "CPF+CNPJ"];
    constructor(
        private toastr: ToastrService,
        private fb: FormBuilder,
        private importacaoService: ConteudoImportacaoService,
        private conteudoService: ConteudoService,
        private comparacaoLeadsService: ComparacaoLeadsService
    ) {}

     ngOnInit() {
       this.initform();

     }
    async initform(){
        this.form = this.fb.group({
            arquivo_importado: [null],
            campanha_id: [null, Validators.required],
            arquivo: this.fb.group({
                nome_arquivo: [""],
                tamanho_arquivo: [""],
                filetype: [""],
                arquivo: ["", Validators.required],
              }),
              comparacoes: this.fb.group({
                de: ["", Validators.required],
                para: ["", Validators.required],
                cnpj: ["",cnpjRequiredValidator()],
              }),
        });
        let conteudos = await  this.conteudoService.get({limit: 1000}).toPromise();
        this.campanhas = conteudos.ConteudoPublicados.map((c: any) => {
            return {
                id: c.id,
                nome: c.identificador,
            };
        }).sort((a, b) => (a.nome < b.nome ? -1 : 1));

    }

    getImportacaoes(campanha){
       this.importacaoService.getAll(campanha.id).subscribe(response => {
            let retorno = response === null ? [] : response;
            this.arquivosImportados = retorno.map((c: any) => {
                return {
                    id: c.id,
                    nome: c.nome_arquivo,
                };
            }).sort((a, b) => (a.nome < b.nome ? -1 : 1));
       });

    }
    enviar() {
        this.form.markAllAsTouched();
        if (this.form.invalid) {
            this.toastr.warning('Por favor verifique os campos obrigatórios!', 'Atenção');
            return false;
        }
        this.comparacaoLeadsService.comparar(this.form.value).subscribe(blob => {
            const url = window.URL.createObjectURL(blob);

            // Cria um link temporário para forçar o download
            const a = document.createElement('a');
            a.href = url;
            a.download = 'comparacao.csv'; // Nome do arquivo
            a.click();

            // Libera a URL para evitar vazamento de memória
            window.URL.revokeObjectURL(url);
            this.toastr.success("A comparação foi concluída e o arquivo com os registros não encontrados foi baixado automaticamente.", "Sucesso!");
        }, error => {
            this.toastr.error("Certifique-se de que os campos selecionados para comparação correspondem exatamente aos títulos das colunas no arquivo .csv enviado.", "Atenção");
        });


    }
    // clearModel() {
    //     this.form.controls.comparacoes.setValue([]);
    // }
    validarUpload(event: Event): void {
        const input = event.target as HTMLInputElement;

        if (input.files && input.files[0]) {
            const file = input.files[0];
            const maxSizeInBytes = 30 * 1024 * 1024;

            if (file.size > maxSizeInBytes) {
                this.fileSizeError = true;
            } else {
                this.fileSizeError = false;
            }
        }
    }
    onFileChange(event: Event) {
        const input = event.target as HTMLInputElement;
        if (input.files && input.files.length > 0) {
            const file = input.files[0];
            const reader = new FileReader();

            // Valida o upload antes de prosseguir
            this.validarUpload(event);

            reader.onload = () => {
                const arquivoBase64 = reader.result as string; // Convertendo para base64
                this.form.controls.arquivo.setValue({
                    nome_arquivo: file.name,
                    tamanho_arquivo: file.size,
                    filetype: file.type,
                    arquivo: arquivoBase64,
                });
            };

            reader.readAsDataURL(file); // Lê o arquivo como base64
        }
    }

}
